import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { NavItem } from '../../utils/types';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import './loyalty.css';

export interface LoyaltyProps {
  number?: string;
  headerText?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const Loyalty: React.FC<LoyaltyProps> = ({
  number,
  headerText = 'EARN LOYALTY POINTS WITH THE',
  style,
  navItems,
  onNavItemSelected
}) => {
  const images = useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "phones.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const phoneImg = images.phone.childImageSharp.fluid;

  return (
    <div id="Loyalty" style={style}>
      {number && (
        <div className="Loyalty-background-text">
          <BigText
            value={number}
            style={{ position: 'absolute', right: '-20px', top: '50px' }}
          />
        </div>
      )}
      <div className="Loyalty-content">
        {number && (
          <DotNav
            selected={parseInt(number, 10) - 1}
            items={navItems || []}
            vertical={true}
            dark={true}
            onChange={onNavItemSelected}
            style={{ marginLeft: '-30px', position: 'absolute' }}
          />
        )}
        <div className="Loyalty-inner-content">
          <div className="Loyalty-title">
            <div className="Loyalty-title__content">
              <div className="Loyalty-title__content-tag">NEW</div>
              <h3 className="Loyalty-title__content-top">{headerText}</h3>
              <h1 className="Loyalty-title__content-main">Fins Mobile App</h1>
            </div>
          </div>
          <div className="Loyalty-details">
            Download the Fins Mobile App and earn points with every single wash
            to use toward rewards. <br />
            <br />
            <strong>How it works:</strong>
            <ol>
              <li>Download Fins Mobile App.</li>
              <li>Create an account.</li>
              <li>Earn points with every single wash purchase.</li>
              <li>Redeem rewards and enjoy access to special offers.</li>
            </ol>
            <strong>Fins Car Wash is Fast. Fun. Fintastic.</strong>
            <div className="Loyalty-actions">
              <a href="https://apps.apple.com/us/app/fins-car-wash/id1468541734">
                <img
                  className="apple"
                  style={{ maxWidth: 216, maxHeight: 56, margin: 0 }}
                  alt="Get it on the Apple Store"
                  src="https://aaacdndev.blob.core.windows.net/wordpress/2019/11/appStoreBadge.png"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.aaa.finsmc&amp;hl=en_US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  className="android"
                  style={{ maxWidth: 216, margin: 0 }}
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="Loyalty-media">
          <Img
            fluid={phoneImg}
            imgStyle={{ objectFit: 'contain' }}
            className="Loyalty-media__image"
          />
        </div>
      </div>
    </div>
  );
};

export default Loyalty;
